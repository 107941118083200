@tailwind components;
@tailwind utilities;
@tailwind base;

@layer base {
  html {
    @apply text-black bg-primary dark:bg-primaryDark dark:text-white;
  }

  h1 {
    @apply text-6xl;
  }

  h3 {
    @apply text-base;
  }

  h4 {
    @apply text-sm;
  }

  p {
    @apply font-body text-sm font-normal leading-relaxed;
  }

  h1,
  h2,
  h3 {
    @apply font-extrabold;
  }

  h4,
  h5,
  h6 {
    @apply font-semibold;
  }

  .pre-wrap {
    white-space: pre-wrap;
  }
}
